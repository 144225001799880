
















































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import Button from '@/app/ui/components/Button/index.vue'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import DataTable from '@/app/ui/components/DataTable/index.vue'
import EditIcon from '@/app/ui/assets/edit_icon.vue'
import EmptyIllustration from '@/app/ui/assets/EmptyState/no_filtered_data.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import Loading from '@/app/ui/components/Loading/index.vue'
import ModalCreatePeriod from '../components/Modals/ModalCreatePeriod/index.vue'
import ModalSuccess from '../components/Modals/ModalSuccess/index.vue'
import controller from '@/app/ui/controllers/PayrollController'
import DateTimePicker from '@/app/ui/components/DateTimePicker/index.vue'
import { LocalStorage } from '@/app/infrastructures/misc'
import { Payroll } from '@/domain/entities/Payroll'
import { Utils } from '@/app/infrastructures/misc'
import dayjs from 'dayjs'
import dayjsID from 'dayjs/locale/id'

@Component({
  components: {
    Button,
    TextInput,
    DataTable,
    PaginationNav,
    EditIcon,
    ModalCreatePeriod,
    ModalSuccess,
    DateTimePicker,
    EmptyIllustration,
    Loading
  },
})

export default class ManageDataMaster extends Vue {
  controller = controller
  modalCreateDataMaster = false
  modalSuccess = false
  latestPeriodDate = ''

  parameters = {
    page: 1,
    limit: 10,
    start_at: '',
    end_at: '',
  }

  headers = ['No.', 'Start Periode Date', 'End Periode Date', '']

  periodList = Array<string[]>([])

  created(): void {
    LocalStorage.removeLocalStorage('selectedPeriod')

    this.fetchPayrollPeriod()
  }

  get params(): Record<string, string | number> {
    let payload: Record<string, string | number> = { ...this.parameters }

    if (!this.parameters.start_at) {
      delete payload.start_at
    } else {
      payload.start_at =
        dayjs(this.parameters.start_at, 'YYYY-MM-DD')
          .utc()
          .toISOString()
    }

    if (!this.parameters.end_at) {
      delete payload.end_at
    } else {
      payload.end_at =
        dayjs(this.parameters.end_at, 'YYYY-MM-DD')
          .utc()
          .toISOString()
    }

    return payload;
  }

  private indexTable(idx: number): string {
    const getCountData = (this.parameters.page - 1) * this.parameters.limit
    return String(getCountData + idx + 1)
  }

  private fetchPayrollPeriod(reset?: boolean) {
    if (reset) this.parameters.page = 1
    controller.getAll(this.params)
  }

  private onSearchPeriod = Utils.debounce(() => {
    this.fetchPayrollPeriod(true)
  }, 400)

  private setPeriodDateLocalStorage(id: number) {
    const selectedPeriod = this.periodList.find((period) => Number(period[0]) === id)
    let currentDate = ''

    if (selectedPeriod) {
      const startDate = selectedPeriod[1].slice(0, -5)
      const endDate = selectedPeriod[2]
      currentDate = `${startDate} - ${endDate}`
    }

    LocalStorage.setLocalStorage('selectedPeriod', currentDate)
  }

  private onEdit(id: number) {
    this.setPeriodDateLocalStorage(id)
    this.$router.push(`/payroll/manage-data-master/edit/${id}/${this.parameters.page}`)
  }

  private onView(id: number) {
    this.setPeriodDateLocalStorage(id)
    this.$router.push(`/payroll/manage-data-master/${id}/${this.parameters.page}`)
  }

  private handleFormatDate(type: string, date?: string) {
    if (type === 'end' && !date) return 'Menunggu Periode Berikutnya'
    if (!date) return '-'
    return dayjs(date)
      .locale(dayjsID)
      .format('DD MMMM YYYY')
  }

  @Watch('controller.dataPayrollList')
  setDataPayrollList(data: Payroll[]): void {
    const result = data.map(list => {
      return [
        list.id,
        this.handleFormatDate('start', list.startAt),
        this.handleFormatDate('end', list.endAt),
        list.status
      ]
    })

    if (data.length === 0) {
      this.latestPeriodDate = dayjs().add(1, 'day').format('YYYY-MM-DD')
    }

    data.forEach(list => {
      if (list.endAt === null) {
        this.latestPeriodDate = dayjs(list.startAt).locale(dayjsID).add(1, 'day').format('YYYY-MM-DD')
      }
    })

    this.periodList = <Array<string[]>>result
  }

  @Watch('controller.isAddPayrollSuccess')
  onIsAddPayrollSuccess(value: boolean): void {
    this.modalCreateDataMaster = false;
    if (value) {
      this.modalSuccess = true;
      this.fetchPayrollPeriod(true)
      controller.setIsAddPayrollSuccess(false)
    }
  }
}
