













































import { Component, Prop, Vue } from 'vue-property-decorator'
import Modal from '@/app/ui/components/Modal/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import DateTimePicker from '@/app/ui/components/DateTimePicker/index.vue'
import CloseLine from '@/app/ui/assets/close_icon_line.vue'
import Loading from '@/app/ui/components/Loading/index.vue'
import controller from '@/app/ui/controllers/PayrollController'
import dayjs from 'dayjs'
import dayjsUTC from 'dayjs/plugin/utc'

dayjs.extend(dayjsUTC)

@Component({
  components: {
    Modal,
    Button,
    DateTimePicker,
    CloseLine,
    Loading
  },
})
export default class ModalCreate extends Vue {
  @Prop({ default: false }) private visible!: boolean
  @Prop({ default: '' }) private latestPeriodDate!: string

  controller = controller
  form = { startDate: '' }

  get latestPeriod(): string {
    return this.latestPeriodDate
  }

  private onSubmit() {
    const payload = { startDate: dayjs(this.form.startDate).utc().toISOString() }
    controller.create(payload)
  }
}
